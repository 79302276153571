import type { BlogTag } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/types';
import {
  getImageUrl,
  type NormalizedCategory,
} from '@wix/communities-blog-client-common';
import type { NormalizedPost } from '../common/types';

type Params = {
  post: NormalizedPost;
  tags: BlogTag[];
  metadata: {
    viewCount: number;
    likeCount: number;
    totalComments: number;
    isLiked: boolean;
  };
  allCategories: NormalizedCategory[];
};

export class PostPageRenderModel {
  post = this.params.post;
  metadata = this.params.metadata;
  tags = this.params.tags;
  categories = this.getPostCategories();
  heroImageUrl = this.post.heroImage
    ? getImageUrl({
        image:
          'src' in this.post.heroImage
            ? this.post.heroImage.src
            : this.post.heroImage,
      })
    : undefined;

  get author() {
    const title = this.getFirstLastName();

    return {
      firstName: title.firstName,
      lastName: title.lastName,
      avatarUrl:
        typeof this.post.owner?.image === 'string'
          ? this.post.owner.image
          : getImageUrl({ image: this.post.owner?.image }),
    };
  }

  constructor(private readonly params: Params) {}

  private getPostCategories(): NormalizedCategory[] {
    const categoriesById = new Map(
      this.params.allCategories.flatMap((category) => [
        [category.id, category],
        [category._id, category],
      ]),
    );

    const isDefined = <T>(value: T | undefined): value is T =>
      value !== undefined;

    return this.post.categoryIds
      .map((id) => categoriesById.get(id))
      .filter(isDefined);
  }

  private getFirstLastName() {
    const [firstName, ...lastNameParts] =
      this.post?.owner?.name?.split(' ') ?? [];

    return {
      firstName,
      lastName: lastNameParts.join(' '),
    };
  }
}
