import type { AppData } from '../../viewer.app';
import blocksModel from './model';

interface DefaultPlugin {
  set resourceId(postId: string);
}

export default blocksModel.createController(({ appData, $w }) => {
  return {
    pageReady: async () => {
      const ui = {
        placeholder1: $w('#slot1') as $w.SlotsPlaceholder<DefaultPlugin>,
        placeholder2: $w('#slot2') as $w.SlotsPlaceholder<DefaultPlugin>,
      };

      const model = await (appData as AppData).fetchPostPageRenderModel();

      if (ui.placeholder1.slot) {
        ui.placeholder1.slot.resourceId = model.post.id;
      }

      if (ui.placeholder2.slot) {
        ui.placeholder2.slot.resourceId = model.post.id;
      }
    },

    exports: {
      ...blocksModel.getExports(),
    },
  };
});
